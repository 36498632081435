.hours {
	display: block;
	@include font-size(17);
	font-weight: $body-weight-bold;
	line-height: 1em;
	letter-spacing: -0.0275em;
	color: $red;

	.site_info & {
		color: $white;
	}

}


