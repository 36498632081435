.block {
	padding: 30px 15px;
}

.block_title {
	margin-bottom: 15px;
}

	.block-title {
		@extend %h2;
		margin: 0;
		font-weight: $heading-weight-bolder;
		text-transform: none;
	}

.block_content {
	> *:last-child { margin-bottom: 0; }
}

.block_more {
	text-align: center;
}