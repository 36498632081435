.page_title {

	padding: 40px 0 0;
	border-bottom: 10px solid theme(accent, base);
	background: theme(highlight, base);

	.title_wrap {
		padding: 10px 0;
	}

	.page-title {
		margin: 0;
		font-weight: $heading-weight-bolder;
		color: $white;
	}

	@include media-breakpoint-up(md) {
		padding-top: 100px;
		border-bottom-color: theme(highlight, base);
		background: theme(highlight, base) url("/_/images/page-title/sunburst.svg") 0 -1px no-repeat;
		background-size: cover;

		.title_wrap {
			background-color: rgba($white, 1);
		}

		.page-title {
			color: #fc8405; //theme(highlight, base);
			color: #ffbb00; //theme(accent, base);
		}

	}
	
	@include media-breakpoint-up(lg) {
		padding-top: 130px;
	}
	
}