img {
	display: block;
	max-width: 100%;
	height: auto;
	border-style: none;
}


	.img-main { margin: 0 0 15px; max-width: 360px; }

	.img-coupon { max-width: 640px; }

	.img-about { width: 150px; }

	.img-auto { width: auto; }

	.img-inline { display: inline; }

	.img-welcome {
		padding: 6px;
		border: 3px solid theme(accent, base);
		background-color: $white;
		&.img--right {
			@include media-breakpoint-up(md) {			
				border-radius: 50%;
				width: 50%;
			}
		}
	}

		.img-thumbnail {
			padding: 5px;
			border: 1px solid #ccc;
			background-color: #fff;
		}

		.img--right {
			@include media-breakpoint-up(md) {
				clear: both;
				float: right;
				width: 45%;
				margin: 0 0 15px 20px;
			}
		}

		.img--left {
			@include media-breakpoint-up(md) {
				clear: both;
				float: left;
				width: 45%;
				margin: 0 20px 15px 0;
			}		
		}

		.img--wide {
			margin: 0 0 15px;
			width: 100%;
			max-width: none;
		}

		.img--auto {
			max-width: auto;
			@include media-breakpoint-up(sm) {
				width: auto; height: auto;
			}
		}

		.img-captioned {
			padding: 5px;
			border: 1px solid #ccc;
			background-color: $white;
			margin-bottom: 15px;
			img {
				width: 100%;
				padding: 0;
				border: none;
				margin-bottom: 0;
			}
			span {
				display: block;
				max-width: 360px;
				margin: 10px 5px 2px;
				font-size: 0.92rem;
				font-style: italic;
				line-height: 1.325em;
				color: theme(text, light);
			}
		}
