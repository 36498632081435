.block_choose {

	text-align: center;
	border-top: 3px solid $white;
	border-bottom: 3px solid $white;
	background: theme(primary, base) url("/_/images/page-title/choose.svg") top center no-repeat;
	background-size: cover;

	color: $white;


	@include media-breakpoint-up(lg) {
		text-align: left;
		.row {
			align-items: center;
			justify-content: center;
		}
		.wrap { width: auto; }
		.choose_more { max-width: 240px; }
	}

	@include media-breakpoint-up(xl) {}

}

	.choose-title {
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid rgba(0,0,0,0.2);
		box-shadow: 0 1px 0 rgba(255,255,255,0.6);
		&::before { content: "Why Choose "; }
		&::after { content: "?"; }
	}

	.choose-list {
		
		text-transform: uppercase;
		font-weight: $body-weight-bold;

		li {
			display: inline-block;
			margin-right: 10px;
			font-weight: $body-weight-bolder;
		}

		@include media-breakpoint-up(lg) {
			overflow: hidden;
			li {
				display: inline-block;
				float: left;

				&:nth-child(odd) {
					clear: both;
					min-width: 220px;
				}

			}
		}
	}

	.choose-img {
		margin: 15px auto 0;
		max-width: 240px;
		@include media-breakpoint-up(lg) {
			margin-top: 0;
		}
	}