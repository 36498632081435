.block_jumbo {

	position: relative;
	padding: 0;

	.container { padding: 30px; }

}

	.jumbo_bg {
		position: absolute;
		top: 0; left: 0;
		right: 0; bottom: 0;
		padding: 30px;
		background: theme(highlight, base) url("/_/images/page-title/sunburst.svg") top center no-repeat;
		background-size: cover;
		opacity: 0.8;

		@include media-breakpoint-up(md) {
			background: transparent url("/_/images/page-title/jumbo.jpg") center center no-repeat;
			background-size: cover;
			opacity: 0.5;
		}

	}

	.jumbo_marketing {
		overflow: visible;
		margin-bottom: 15px;
		
		.slide_caption {
			text-transform: uppercase;
			br { display: none; }
		}

		@include media-breakpoint-up(md) {
			.slide_caption {
				background-color: rgba($color-secondary, 0.9);
				.caption-title { @include font-size(24); }
				.caption-text { @include font-size(18); }
			}
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			.slide_caption {
				padding: 45px 15px;
				text-align: left;
				border: none;
				background: none;
				//@include transform(skewY(-7deg));
				br { display: block; }
				.caption-title {
					display: inline-block;
					padding: 12px 20px 15px;
					@include font-size(32);
					border: 2px solid $white;
					background-color: theme(secondary, base);
				}
				.caption-text {
					display: inline-block;
					margin: -10px 0 0 20px;
					padding: 10px 15px;
					@include font-size(20);
					border: 2px solid $white;
					background-color: theme(primary, base);
				}
			}			
		}

		@include media-breakpoint-up(xl) {
			.slide_caption {
				.caption-title { @include font-size(36); }
				.caption-text { @include font-size(24); }
			}
		}



	}

	.jumbo_conversion {
		.form { background-color: $white; }
		@include media-breakpoint-up(md) {
			.form { background-color: rgba($white, 0.85); }
		}
	}