// 240 x 118
// 270 x 133
// 300 x 147

.logo {
	display: block;
	overflow: visible;
	width: 200px;
	height: 200px;
	margin: -40px auto 15px;
	text-align: center;
	text-indent: -9999px;
	background: url("/_/images/layout/logo.png") top center no-repeat;
	background-size: contain;
	@include transform(scale(0.97));
	@include transition(transform ease-in-out 200ms);
	&:hover {
		@include transform(scale(1) rotate(13deg));
		@include transition(transform ease-in-out 200ms);
	}
}

	.logo-footer {
		display: block;
		max-width: 215px;
		margin: 0 auto 10px;
	}


.gmb-rating {
	display: inline-block;
	margin: 0 auto 15px;
	line-height: 1em;
	text-align: center;
	text-decoration: none;
	color: $color-text !important;
	@include transform(scale(0.95));
	img {
		display: inline-block;
		max-width: 150px;
	}
	small {
		display: block;
		@include font-size(14);
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		letter-spacing: -0.0325em;
	}
}