.slider_jumbo {

    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    line-height: 0px;

    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slide_caption {
        padding: 15px;
        text-align: center;
        text-shadow: 0 1px 1px rgba(0,0,0,0.25);
        border: 3px solid $white;
        background-color: theme(secondary, base);
        color: $white;
    }

        .caption-title {
            margin: 0;
            @include font-size(20);
            font-weight: $body-weight-bolder;
            line-height: 1em;
            letter-spacing: -0.0375em;
        }

        .caption-text {
            margin: 0;
            @include font-size(16);
            font-weight: $body-weight-bold;
            line-height: 1em;
        }

    @include media-breakpoint-up(md) {}
    @include media-breakpoint-up(lg) {}

}