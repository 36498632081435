.site_info {
	padding: 30px 0;
	text-align: center;
	background-color: theme(secondary, base);
	color: $white;

	.page_front & {

		@include media-breakpoint-up(md) {
			position: relative;
			margin: 0;
			padding: 10px 0 30px;

			&::before,
			&::after {
				bottom: 100%;
				left: 50%;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: transparent;			
			}

			&::after {
				border: solid transparent;
				border-left: 130px solid transparent;
				border-right: 130px solid transparent;
				border-bottom: 40px solid transparent;
				border-bottom-color: theme(secondary, base);
				margin-left: -130px;
			}

			&::before {
				border: solid transparent;
				border-left: 143px solid transparent;
				border-right: 143px solid transparent;
				border-bottom: 47px solid transparent;
				border-bottom-color: $white;
				margin-left: -143px;
			}

			.info_company {
				@include make-flex();
				align-items: center;
				justify-content: center;
				.company_logo { width: 100%; }
				.company-call,
				.company-address {
					width: 50%;
					padding: 0 20px;
					text-align: left;
				}
				.company-call { text-align: right; }
			}

		}

	}

}