.block_brands {
	
	text-align: center;
	//border-top: 3px solid theme(secondary, base);
	border-bottom: 3px solid #ddd; //theme(secondary, base);
	background-color: $white;

	.brands_title {
		margin-bottom: 30px;
		//color: theme(secondary, base);
	}

	.brands_wrapper {
		overflow: hidden;
		img {
			display: inline-block;
			width: 50%;
			max-width: 150px;
			//padding: 5px;
			//border: 1px solid #eee;
			//background-color: $white;
		}
	}

/*	@include media-breakpoint-up(lg) {
		.brands_wrapper img {
			display: block;
			float: left;
			width: 50%;
			max-width: none;
		}
	}*/

}