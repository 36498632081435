.coverage {

	padding: 30px;

	.coverage_title {
		margin: 0 0 0.75rem;
		font-size: 1rem;
		font-weight: $heading-weight-bolder;
		text-transform: uppercase;
		color: theme(secondary, base);
	}

	p { 
		margin-bottom: 0.5rem;
		line-height: 1.325em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage_county {
			font-size: 1rem;
			font-weight: $body-weight-bold;
		}

		.coverage_city {
			font-size: 0.825rem;
		}

}

.block.block_coverage {
	border-top: 5px solid $white;
	border-bottom: 5px solid $white;
	background-color: theme(primary, base);
	color: $white;
	text-align: center;
	box-shadow: none;

	.row { justify-content: center; }

	.coverage_title { color: $white; }

 	@include media-breakpoint-up(lg) { .wrap { @include make-col(9); } }
	@include media-breakpoint-up(xl) { .wrap { @include make-col(8); } } 

	.page_front & {
		@include media-breakpoint-up(md) { padding-bottom: 60px; }
	}

}