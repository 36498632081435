.form_quick {

	@include media-breakpoint-only(md) {
		.form_control { margin: 0; }
		.form_left,
		.form_right { width: 49.25%; }
		.form_left { float: left; }
		.form_right { float: right; }
		.form_clear { clear: both; }
	}

	@include media-breakpoint-only(lg) {
		.form_spam {
			position: relative;
			height: 64px;
			.g-recaptcha {
				position: absolute;
				top: -6px; left: -24px;
				@include transform(scale(0.845));
			}
		}
	}

	.sidebar & {
		margin-bottom: 15px;
		border: 3px solid theme(primary, base);
		background-color: $white;
	}


}