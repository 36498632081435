.banner_menu {
	
	background-color: theme(secondary, base);

	.container { padding: 0; max-width: 1140px; }
	.row { margin: 0; }
	.wrap { padding: 0; }

	@include media-breakpoint-up(lg) {
		margin-top: -55px;
		.wrap {
			width: 730px;
			margin-left: auto;
		}
	}

	@include media-breakpoint-up(xl) {
		.wrap {
			width: 890px;
		}
	}

	.menu {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;

		> .menu_item {

			display: block;
			float: left;
			width: percentage(1/7);

			&:not(:last-child) { border-right: 1px solid rgba(0,0,0,1); }
			&:not(:first-child) { border-left: 1px solid rgba(255,255,255,0.4); }

			&.active {
				> .menu_link {
					background: $body-bg;
					color: theme(secondary, base);
				}
			}

			&.open {
				> .menu_link {
					background-color: theme(primary, base);
					.caret { background-image: url("/_/images/icons/caret.png"); }
				}
			}

		}
	}

	.menu_link {
		@include transition(all ease-in-out 400ms);
		display: block;
		padding: 14px 15px 13px;
		@include font-size(13);
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0325em;
		text-transform: uppercase;
		background: url("/_/images/icons/hover-primary.svg") center center no-repeat;
		background-size: 0px !important;
		color: $white;
		&:hover {
			@include transition(all ease-in-out 400ms);
			background-size: 200% !important;
		}
	}



	@include media-breakpoint-between(md, lg) {
		.menu > .menu_item:nth-child(1) {
			.menu_link { padding: 20px 15px; }
		}
	}

	@include media-breakpoint-up(xl) {
		.menu > .menu_item {
			
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(6) {
				> .menu_link { padding: 14px 10px 13px; }
			}

			> .menu_link {
				padding: 20px 10px;
				br { display: none; }
			}

		}
	}

}
