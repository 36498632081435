.banner {
	padding: 15px 0;
	text-align: center;
	border-top: 10px solid theme(primary, base);

	@include media-breakpoint-up(md) {

		.banner_branding {
			text-align: left;
			padding-left: 0;
		}

		.logo, .gmb-rating {
			display: inline-block;
			margin: 0;
			vertical-align: middle;
			text-align: left;
		}

		.contact-call { text-align: right; }

	}

	@include media-breakpoint-up(lg) {
		padding-top: 10px;
		.banner_contact { align-self: flex-start; }
		.contact-call { margin-top: 57px; }
		.branding-title,
		.logo { width: 210px; height: 210px; }
	}

	@include media-breakpoint-up(xl) {
		.branding-title,
		.logo { width: 220px; height: 220px; }
	}

}

