.announce {

	padding: 15px;
	
	@include font-size(15);
	font-weight: $body-weight-bolder;
	line-height: 1em;
	text-transform: uppercase;
	letter-spacing: -0.0325em;
	text-shadow: 0 1px 1px rgba(0,0,0,0.25);

	border-top: 3px solid $white;
	border-bottom: 3px solid $white;
	background-color: theme(primary, base);
	//background-color: theme(accent, base);
	color: $white;

	@include media-breakpoint-up(md) {
		ul {
			@include limbo;
			li {
				display: block;
				float: left;
				width: percentage(1/3);
			}
		}
	}

}