.call {
	line-height: 1em;

	.phone {
		display: inline-block;
		@include font-size(32);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0425em;		
		&.company-phone { color: $white; }
	}

	&.contact-call {
	}

}


